import type {
  HeadlineResponse,
  LatestForumPostsResponse,
  NilHundredResponseNil100FilterResponseRelatedPagedData,
  PlayerItemResponsePagedData,
  RelatedScheduleResponse,
  ScoreboardResponse,
  SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData,
} from '@on3/api';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import clsx from 'clsx';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { Forums } from 'components/Forums/Forums';
import { CondensedList } from 'components/Headlines/ArticleCondensed/CondensedList';
import { CoverList } from 'components/Headlines/ArticleCover/CoverList';
import { FeedList } from 'components/Headlines/ArticleFeed/FeedList';
import { PremiumArticleList } from 'components/Headlines/PremiumArticle/PremiumArticleList';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { SignUp } from 'components/Newsletter/SignUp';
import { Nil100Widget } from 'components/Nil/Nil100Widget';
import { PianoPromo } from 'components/Promos/PianoPromo';
import { ScheduleModule } from 'components/Schedules/Sidebar/ScheduleModule';
import { TeamConnect } from 'components/Social/TeamConnect';
import { TributeGary } from 'components/Social/TributeGary';
import { LatestCommits } from 'components/Teams/LatestCommits/LatestCommits';
import { useMemo } from 'react';
import { useAds } from 'utils/hooks/useAds';

import { removeDuplicateArticles } from './articleHelper';
import { SEOHomeText } from './SEOHomeText';
import styles from './TraditionalHome.module.scss';

export interface ITraditionalHomeProps {
  featuredArticles?: HeadlineResponse[];
  siteSports?: HeadlineResponse[];
  siteRecruiting?: HeadlineResponse[];
  channelSports?: HeadlineResponse[];
  channelRecruiting?: HeadlineResponse[];
  messageBoard?: LatestForumPostsResponse;
  latestCommits?: PlayerItemResponsePagedData;
  teamNil100?: NilHundredResponseNil100FilterResponseRelatedPagedData;
  premiumArticles?: SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData;
  teamSchedule?: {
    list?: ScoreboardResponse[];
    relatedModel?: RelatedScheduleResponse;
  };
}

export const TraditionalHome = ({
  featuredArticles,
  siteSports,
  siteRecruiting,
  channelSports,
  channelRecruiting,
  messageBoard,
  latestCommits,
  teamNil100,
  premiumArticles,
  teamSchedule,
}: ITraditionalHomeProps) => {
  const { currentSite } = useSite();

  const { list: nil100list } = teamNil100 || {};
  const { siteName, key: siteKey, url: siteUrl } = currentSite || {};
  const siteColor = currentSite?.primaryColor || '#fe3b1f';

  useAds({ pageType: 'home' });

  const sportViewAll = useMemo(() => {
    return [
      {
        url: `${siteUrl}/category/football/news/`,
        text: 'View All Football',
        title: 'Football News',
      },
      {
        url: `${siteUrl}/category/basketball/news/`,
        text: 'View All Basketball',
        title: 'Basketball News',
      },
    ];
  }, [siteUrl]);

  const recruitingViewAll = useMemo(() => {
    return [
      {
        url: `${siteUrl}/category/football-recruiting/news/`,
        text: 'View All Football Recruiting',
        title: 'Football Recruiting News',
      },
      {
        url: `${siteUrl}/category/basketball-recruiting/news/`,
        text: 'View All Basketball Recruiting',
        title: 'Basketball Recruiting News',
      },
    ];
  }, [siteUrl]);

  const coverArticles = featuredArticles?.slice(0, 3);
  const coverKeys = coverArticles?.map((article) => article?.key);
  const collegeSports = removeDuplicateArticles({
    list: siteSports,
    keys: coverKeys,
  })?.slice(0, 5);

  const footballRecruiting = removeDuplicateArticles({
    list: siteRecruiting,
    keys: coverKeys,
  })?.slice(0, 5);

  const flipModules = siteKey === 367 || siteKey === 395;

  return (
    <>
      {!!coverArticles?.length && (
        <CoverList
          authorColor="#fff"
          backgroundColor={siteColor}
          categoryColor="#fff"
          list={coverArticles}
          titleColor="#fff"
        >
          <PianoPromo id="pianoPromoDark" />
        </CoverList>
      )}
      <HomeLayout>
        <SEOHomeText />
        <section className={styles.feed}>
          <div className={clsx({ [styles.middleFeeds]: flipModules })}>
            {!collegeSports?.length || (
              <FeedList
                list={collegeSports.slice(0, 5)}
                section={`${siteName} Sports`}
                viewAllLinks={sportViewAll}
              />
            )}
            <MobileMPU id={'mobile_mpu_top'} incontent />
            {!footballRecruiting?.length || (
              <FeedList
                list={footballRecruiting.slice(0, 5)}
                section={`${siteName} Recruiting`}
                viewAllLinks={recruitingViewAll}
              />
            )}
          </div>
        </section>
        <aside className={styles.sidebarright}>
          <MPU id={'mpu_top'} />
          {!!premiumArticles?.list?.length && (
            <PremiumArticleList
              list={premiumArticles?.list}
              relatedModel={premiumArticles?.relatedModel}
            />
          )}

          {latestCommits?.list?.length && (
            <LatestCommits list={latestCommits?.list} />
          )}
          {siteKey === 79 && <TributeGary />}

          {!messageBoard?.posts?.length || <Forums list={messageBoard} />}
          <MPU className={styles.sticky} id={'mpu_middle'} />
          <MobileMPU id={'mobile_mpu_middle'} incontent />
        </aside>
        <aside className={styles.sidebarleft}>
          {(!channelSports?.length && !channelRecruiting?.length) || (
            <CondensedList
              list={channelSports}
              secondaryList={channelRecruiting}
            />
          )}
          {!!nil100list?.length && (
            <Nil100Widget list={nil100list?.slice(0, 5)} />
          )}
          {!!teamSchedule?.list?.length && (
            <ScheduleModule
              list={teamSchedule?.list}
              relatedModel={teamSchedule?.relatedModel}
            />
          )}
          <TeamConnect />
        </aside>
      </HomeLayout>
      <SignUp titleText={`${siteName} Newsletter`} />
    </>
  );
};
